.background{
  background: url('../assets/background.png') center;
}

.heroSection {
  background: url('../assets/background.gif') center;
    position: relative;
    /* background-size: cover; */
    text-align: center;
    height: 100vh;
    /* color: #cf3f3f;  */
    /* border-bottom-left-radius: 90% 30%; 
    border-bottom-right-radius: 90% 30%; */
    overflow: hidden;
  }

  .heroSection::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .heroImage{
    margin-top: 40px;
    width: 30rem;
    /* height: 30rem; */
    object-fit: cover; 
    /* border-radius: 50px;  */
    z-index: 1;
  }
  
  .heroText{
    position: absolute;
    text-align: center;
    margin: auto;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50%;
    width: 40%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* border: 9px solid #395ce0; */
    border-radius: 50px; /* Add curved edges */
    background-color: rgba(57, 92, 224, 0.8); /* #395ce0 with 80% opacity */
  }
  .heroText h1 {
    font-size: 50px; /* Large font size for the heading */
    color: white;
  }
  
  .heroText p {
    font-size: 20px; /* Slightly larger font size for the paragraph */
    margin-bottom: 40px;
    color: white;
    font-weight: 500;
  }

  @media (max-width: 768px) {
  
    .heroImage{
      width: 20rem;
    }
    .heroText{
      width: 80%;
    }

    .heroText h1{
       font-size: 40px;
    }

    .heroText p {
      font-size: 17px; 

    }

  }
  
  .startIntakeButton {
    background-color: #f6454f; /* Your brand's orange color */
    color: white;
    border: none;
    padding: 20px 25px;
    width: 200px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 40px; 
    transition: background-color 0.3s ease-in-out;
    font-weight: bold;
  }
  
  .startIntakeButton:hover {
    background-color: #f6121d; /* Darker shade on hover */
  }

  .sectionContainer {
    padding: 40px; 
    color: white; 
    background-color: #f6454f;
    /* background: url('../assets/background-red.png') center; */
  }
  
  .sectionContainer h2 {
    font-size: 3rem; /* Large font size for the heading */
    color: white; /* Black color for the heading */
    margin-bottom: 20px; /* Space below the heading */
    text-align: center; /* Center the heading */
    position: relative; /* For the pseudo-element line */
  }
  
  .sectionContainer h2::before {
    content: '';
    width: 100px; /* Width of the horizontal line */
    height: 4px; /* Thickness of the line */
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    margin-top: -20px; /* Distance from the heading text */
  }
  
  .sectionContent {
    max-width: 700px; /* Maximum width of the content */
    margin: 0 auto; /* Center the content block */
    font-size: 1.1rem; /* Regular font size for content */
    line-height: 1.7; /* Line height for readability */
    text-align: center;
    color: white; 
    font-weight: 500;
  }

  .sectionContent p{
    color: white; 
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    
  
    .sectionContainer h2 {
      font-size: 2rem; /* Smaller heading on small screens */
    }

    .sectionContent{
      margin-top: 60px;
      font-size: 1rem; /* Smaller heading on small screens */
      font-weight: 600;
    }
  

  }

  .therapyPlansContainer {
    text-align: center; /* Center the main title */
    padding: 50px;
    background: url('../assets/background.png') center;
  }
  
  .uniqueTitle {
    font-size: 4rem; /* Larger font size for the main title */
    color: #395ce0; /* Or any color you prefer */
    margin-bottom: 50px; /* Space below the main title */
    position: relative; /* Needed for absolute positioning of the pseudo-element */
    display: inline-block; /* Allows the ::before element to align with the text properly */
    padding-top: 30px;
  }
  
  .uniqueTitle::before {
    content: '';
    width: 80px; /* Width of the horizontal line */
    height: 4px; /* Thickness of the line */
    background-color: #f6454f; /* Color of the horizontal line */
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    margin-top: -20px; /* Adjust if needed to position the line above the title */
  }

  .therapyPlansSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 20px; */
  }

  .guidanceSupportContainer {
    /* margin-top: 50px; */
    display: flex;
    color: #000;
    /* padding: 20px; */
    align-items: center; /* Align the image and text vertically */
    justify-content: center; /* Center the content horizontally */
  }

  .blob2{
    background: url('../assets/blob2.svg') no-repeat center;
    background-size: 50rem;
    background-position: right -85px bottom 15px;
  }

  .blob3{
    background: url('../assets/blob3.svg') no-repeat center;
    background-size: 50rem;
    background-position: right -100px bottom -5px;
  }

  .blob4{
    background: url('../assets/blob4.svg') no-repeat center;
    background-size: 52rem;
    background-position: right -85px bottom -110px;
  }
  
  .textContent {
    flex: 1; /* Take up half the width of the container */
    padding: 120px; 
    position: relative; 
    width: 20rem;
  }
  
  .textContent h2 {
    font-size: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    z-index: 1;
    /* background: url('../assets/blob2.svg') no-repeat center;  */
  }
  
  .textContent p {
    font-size: 15px;
    color: white; /* Or any color you prefer */
    line-height: 1.3;
    z-index: 1;
    font-weight: 500;
    text-align: center;
    text-wrap:balance;
  }

  .textContainer {
    flex: 1; /* Take up half the width of the container */
    padding: 120px; 
    position: relative; 
    /* text-align: left; */
    width: 20rem; /* Space between the image and the text */

  }
  
  .textContainer h2 {
    font-size: 2rem; /* Larger font size for the heading */
    margin-bottom: 0px; /* Space below the heading */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    color: white;
  }
  
  .textContainer p {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.3;
    text-align: center;
    color: white;
    text-wrap:balance;
  }
  
  .imageContent {
    flex: 1; /* Take up the other half */
    position: relative; /* For positioning the bee logo absolutely */
  }

  .childImage {
    /* margin-top: 40px; */
    width: 30rem;
    height: 25rem;
    object-fit: cover; 
    border-radius: 50px; 
    border: 9px solid #f6454f;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .therapyPlansSection {
      flex-direction: column;
      padding: 30px;
    }

    .therapyPlansContainer {
      text-align: center; /* Center the main title */
      padding: 0px;
    }

    .imageContent {
      width: 100%; /* Full width */
    }

    .blob2{
      background-size: 55rem;
      background-position: right -145px bottom -35px;
    }

    .blob3{
      background-size: 51rem;
      background-position: right -155px bottom -55px;
    }

    .blob4{
      background-size: 55rem;
      background-position: right -105px bottom -150px;
    }

    .textContent {
      padding: 85px; 
      position: relative; 
      width: 20rem;
    }

    .textContainer {
      padding: 90px; 
      position: relative; 
      width: 20rem;
    }

    .textContent p{
      font-weight: 600;

    }

    .textContainer p{
      font-weight: 600;
    }
    .childImage{
      display: none;
    }
  
  }
  
  
  .familyImage {
    width: 100%; /* Make image responsive */
    height: auto;
    border-radius: 10px; /* Rounded corners for the image */
  }
  

  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    .guidanceSupportContainer {
      /* margin-top: 50px; */
    
      flex-direction: column;
      padding: 30px;
    }
  
    .imageContainer {
      flex-basis: auto; /* Allow image container to size automatically */
    }
  
  }



  @media (max-width: 768px) {
    .serviceSection {
      flex-direction: column;
      align-items: center; /* Center align the cards when stacked */
    }
    .serviceSection::before {
      /* Adjust the line or remove if not needed for mobile */
      margin-top: -1000px; /* Adjust this value to fit your design */
    }

  }

.servicesTitle{
  margin: 40px auto;
  text-align: center;
  font-size: 4rem; /* Larger font size for the main title */
  color: #395ce0;
  
}

.servicesContainer{
  background: url('../assets/background.png') center;
}