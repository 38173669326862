.background{
  background: url('../assets/background.png') center;
}

.coFoundersContainer {
  text-align: center;
  padding: 20px;
  /* background: url('../assets/wave-haikei.svg') ; */
  background-color: #f6454f;
  /* background: url('../assets/background-red.png') center; */
}

.aboutTitle{
  margin: 0 auto;
  text-align: center;
  font-size: 4rem; /* Larger font size for the main title */
  color: #395ce0;
  padding: 3rem;
}

.coFoundersContainer h2 {
  /* margin-bottom: 20px; */
  color: white;
}

.founders {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  width: 40rem;
  padding: 10px;
}

.founder {
  /* background: rgb(254, 246, 236); */
  padding: 20px;
  margin: 0 10px;
  flex: 1;
  /* border: white solid 3px; */
  border-radius: 20px;
  height: 25rem;
}

.founder h3 {
  color: #c0121b;
  font-size: 35px;
  font-weight: 700;
}

.founder p {
  text-align: center;
  color: white;
  font-weight: 500;
}

@media (max-width: 768px) {
  .founders {
    flex-direction: column;
    margin: 0;
    width: 100%;
    padding: 0%;
  }

  .founder {
    margin-bottom: 20px;
  }

  .founder p{
    font-weight: 600;
  }

  .aboutTitle{
    margin: 0 auto;
    text-align: center;
    font-size: 2.5rem; /* Larger font size for the main title */
    color: #395ce0;
    padding: 2rem;
  }
}
