.footer {
  background-color: #f9f9f9;
  padding: 40px 20px;
  text-align: center;
  font-family: Arial, sans-serif;
}

.footerTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.contactInfo {
  font-size: 14px;
  color: #707070;
  margin: auto;
}

.contactInfo a {
  color: #707070;
  text-decoration: none;
  margin-right: 10px;
}

.logo img {
  height: 60px; /* Adjust the size of your logo */
}

.footerLinks {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-bottom: 20px;
}

.footerLinks a {
  color: #707070;
  text-decoration: none;
  font-size: 14px;
}

.footerBottom {
  margin-top: 20px;
}

.footerBottom hr {
  border: 0;
  height: 1px;
  background-color: #e0e0e0;
  margin-bottom: 10px;
}

.footerBottom p {
  font-size: 12px;
  color: #707070;
  margin: auto;
}
