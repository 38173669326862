.insuranceContainer {
    text-align: center;
    padding: 40px;
}

.insuranceGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-template-rows: auto auto; /* Force two rows */
    gap: 20px; /* Space between logos */
    justify-items: center; /* Center each logo */
    align-items: center; /* Align items vertically */
}

.insurance img {
    max-width: 100px; /* Adjust width as needed */
    height: auto;
    transition: transform 0.3s ease-in-out;
}

.insurance img:hover {
    transform: scale(1.1); /* Slightly enlarge logos on hover */
}

@media (max-width: 768px) {
    .insuranceGrid {
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)); /* Adjust for smaller screens */
    }
}


.sectionContainer{
  text-align: center;
  padding: 10px;
  height: 22rem;
  border: 9px solid #f6454f;
  border-radius: 50px; /* Add curved edges */
  background-color: white;
  /* width: fit-content; */
  max-width: 40rem;
  margin: 5rem auto;
}
  
  .sectionContainer h2 {
    font-size: 3rem; /* Large font size for the heading */
    color: #395ce0; /* Black color for the heading */
    margin-bottom: 20px; /* Space below the heading */
    text-align: center; /* Center the heading */
    position: relative; /* For the pseudo-element line */
    /* padding-top: 30px; */
  }
  
  
  .sectionContent {
    max-width: 700px; /* Maximum width of the content */
    margin: 0 auto; /* Center the content block */
    font-size: 17px; /* Regular font size for content */
    line-height: 1.7; /* Line height for readability */
    text-align: center;
    text-wrap: balance;
    font-weight: 500;
    
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    .sectionContainer {
      padding: 30px; /* Smaller padding on small screens */
      width: 80%;
    }
  
    .sectionContainer h2 {
      font-size: 2rem; /* Smaller heading on small screens */
    }
  
    .sectionContent {
      padding: 0 15px; /* Padding to prevent text from touching the edges */
    }
  }