.careersPage p {
    color: #000;
    font-weight: 400;
    line-height: 1.5;
    font-size: 15px;
}

.careersPage li {
    color: #000;
    font-weight: 400;
    line-height: 1.5;
    font-size: 15px;
}
.jobHeader h2 {
    color: #000;
    font-size: 35px;
    text-align: left;
}

.careersPage {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.jobHeader {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  margin-bottom: 20px;
}

.jobHeader h1 {
  font-size: 24px;
  margin: 0;
}

.jobMeta {
  display: flex;
  gap: 20px;
}


.jobContent {
  display: flex;
  gap: 40px;
}

.jobDescription {
  flex: 1;
}

.jobDescription h2 {
  color: #000;
}

.applicationForm {
  flex: 1;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 5px;
}

.applicationForm h1 {
  color: #000;
}

.applicationForm label {
  color: #000;
}

.required {
  color: red !important;
  margin-bottom: 10px;
}


.formRow {
  display: flex;
  gap: 10px;
}

.applicationForm input[type="text"],
.applicationForm input[type="email"],
.applicationForm input[type="tel"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

label {
  display: block;
  margin-bottom: 5px;
}

.resumeUpload {
  border: 1px dashed #ccc;
  padding: 10px;
  text-align: center;
  margin-bottom: 15px;
}

.resumeUpload span {
  color: #2c8dc5;
  cursor: pointer;
}

.submitButton {
  background-color: #2c8dc5;
  color: white;
  border: none;
  padding: 10px 20px;
  width: 100%;
  border-radius: 3px;
  cursor: pointer;
}

/* Add responsive design for smaller screens */
@media (max-width: 768px) {
  .jobContent {
    flex-direction: column;
  }
}

form{
    color: #000;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 20px; */
}

.logo {
  height: 90px; 
  width: auto;
}

.backButton {
  display: inline-block;
  padding: 10px 20px;
  background-color: #f0f0f0;
  color: #333;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.backButton:hover {
  background-color: #e0e0e0;
}
