.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* Add this to create space for the fixed navbar */
body {
    padding-top: 80px; /* Adjust this value to match your navbar height */
}



.nav-toggle {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    height: 21px;
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 10; /* Ensure it's above the menu */
}

.hamburger,
.hamburger::before,
.hamburger::after {
    display: block;
    background-color: #f6454f;
    height: 3px;
    width: 30px;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.hamburger::before,
.hamburger::after {
    content: '';
    position: absolute;
}

.hamburger::before {
    transform: translateY(-10px);
}

.hamburger::after {
    transform: translateY(10px);
}

/* X button styles */
.nav-toggle.open .hamburger {
    background-color: transparent;
}

.nav-toggle.open .hamburger::before {
    transform: rotate(45deg);
}

.nav-toggle.open .hamburger::after {
    transform: rotate(-45deg);
}

.nav-links {
    display: flex;
    gap: 5px;
    margin-left: auto;
}

.nav-links a {
    text-decoration: none;
    color: #333;
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.nav-links a:hover {
    background-color: #f6454f;
    cursor: pointer; /* Adjust the hover background color */
}

/* Styles for when menu is opened */
.nav-links.show {
    font-size: 25px;
    font-weight: 500;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 0;
    position: absolute;
    top: 80px; 
    left: 0;
    right: 0;
    background-color: white;
    /* padding: 20px; */
    /* border-top: 1px solid #ccc;  */
    height: fit-content;
    width: 100%;
    z-index: 5;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .nav-links {
        display: none;
    }

    .nav-toggle {
        display: flex;
    }

    .get-started-button {
        display: none;
    }
}

/* Styles for active state */

.get-started-button {
    text-decoration: none;
    margin-left: 20px;
    color: #ffffff;
    background-color: #f6454f;
    padding: 10px 20px;
    border-radius: 20px;
    transition: background-color 0.3s;
}

.get-started-button:hover {
    background-color: #f6121d; 
    cursor: pointer;/* Darker shade on hover for the button */
}
