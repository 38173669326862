section{
    background-color: #f6454f;
    padding: 3rem;
}

h2 {
    font-size: 4rem; /* Large font size for the heading */
    color: #ffffff; /* Black color for the heading */
    margin-bottom: 20px; /* Space below the heading */
    text-align: center; /* Center the heading */
    position: relative; /* For the pseudo-element line */
  }

form {
  padding: 20px;
  border-radius: 10px;
  max-width: 30rem; 
  margin: 10px auto;
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 20px;
}


/* label {
  font-size: 14px;
  margin-bottom: 5px;
} */

input[type="text"],
input[type="tel"],
input[type="email"] {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 22px;
  width: 100%;
  box-sizing: border-box;
}

input[type="date"]{
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 22px;
    width: 50%;
    box-sizing: border-box;
    text-align: center;
}
.email{
    text-align: center;
}

.message {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 22px;
  width: 100%;
  box-sizing: border-box;
  resize: none; /* Disable manual resizing by the user */
  overflow: hidden; /* Prevents scrollbar from showing */
  min-height: 100px; /* Set a minimum height */
  height: auto; /* Make it auto-sized initially */
}

/* Auto-expanding behavior */
.message:focus {
  border-color: #007bff;
  outline: none;
}


input:focus {
  border-color: #007bff;
  outline: none;
}

button[type="submit"] {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #395ce0;
  border: none;
  border-radius: 22px;
  cursor: pointer;
  align-self: flex-start;
  margin: auto;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

/* Flexbox layout for two-column rows */
.formRow {
  display: flex;
  gap: 20px; 
  color: #fff;
}

label {
  color: #fff;
}

.formRow div {
  flex: 1;
}

@media (max-width: 768px) {
    .formRow{
    flex-direction: column;
  }
  label {
    font-weight: 600;
  }
}
