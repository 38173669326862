.service-card {
    text-align: center;
    padding: 10px;
    height: 22rem;
    border: 9px solid #f6454f;
    border-radius: 50px; /* Add curved edges */
    background-color: white;
    /* width: fit-content; */
    max-width: 50rem;
    margin: 5rem auto;
  }
  
  
  .service-icon {
    margin-bottom: 20px;
  }
  
  h1 {
    color: #395ce0; /* Adjust the color to fit your design */
    margin-bottom: 10px;
  }
  
  p {
    color: #1e3aa0; /* Adjust the color to fit your design */
    margin-bottom: 20px;
    line-height: 2rem;
    text-wrap:balance;
  }
  
  .learn-more-btn {
    background-color: transparent;
    border: none;
    color: #007bff; /* Example blue color for the button text */
    cursor: pointer;
  }

  img {
    width: 7rem;
  }

  @media (max-width: 768px) {
    .service-card {
      width: 80%; /* Full width cards on mobile */
      height: auto;
      padding: 3rem 0;
    }
  }